<script lang="ts">
	import { page } from '$app/stores'
	import { signOut } from '$lib/authentication/firebase'
	import state from '$lib/stores/auth-state'
	import { createEventDispatcher } from 'svelte'
	import modal from '$lib/stores/modal-state'
	import AccountMenu from './AccountMenu.svelte'
	import Button from '../buttons/Button.svelte'
	import MenuIcon from '../icons/MenuIcon.svelte'
	import { track } from '$src/utils/track'
	// import Banner from './ui/display/Banner.svelte';

	$: layoutType = $page.data.layoutType
	const dispatch = createEventDispatcher<{ open: NavType; close: void; startTrip: void }>()

	// $: isBannerBlocked = $page.data.blockBanner;
	$: signedIn = $state.signedIn
	// $: currentUser = $state.currentUser

	function handleStartTrip() {
		dispatch('startTrip')
	}

	function handleShowLoginClick(event: MouseEvent) {
		event.preventDefault()
		track('Sign In Clicked')
		modal.open('signup', { intent: 'signIn' })
	}

	function handleClickOnMobile(event: MouseEvent | TouchEvent) {
		event.preventDefault()
		track('Mobile Menu Clicked')
		dispatch('open', 'mobile')
	}

	function handleSignOut(event: CustomEvent) {
		event.preventDefault()
		track('Sign Out Clicked')
		signOut()
	}
</script>

<nav
	class={`fixed mx-auto top-0 mb-[2px] left-0 right-0 ${
		layoutType === 'itinerary' || layoutType === 'profile'
			? 'bg-transparent lg:hidden'
			: 'bg-white shadow-sm'
	} z-40 flex flex-col items-center justify-center`}
>
	<!-- {#if !isBannerBlocked && $page.data.banner}
		<Banner banner={$page.data.banner} />
	{/if} -->

	<div class="max-w-screen-xl flex items-stretch justify-between h-16 px-2 lg:px-8 2xl:px-6 w-full">
		<div class="flex px-0 lg:px-0 items-center flex-shrink-0 lg:pr-6">
			{#if layoutType === 'itinerary'}
				<a href="/" class="items-center leading-none hidden lg:flex">
					<img src="/airheart-logo-full-new.svg" height={40} width={148} alt="Airheart Logo" />
				</a>
			{:else if layoutType !== 'profile'}
				<a href="/" class="flex items-center leading-none">
					<img src="/airheart-logo-full-new.svg" height={40} width={148} alt="Airheart Logo" />
				</a>
			{/if}
		</div>

		<div class="hidden lg:flex">
			<a
				href="/guide"
				class="linkUnderline flex items-center justify-center w-36 pt-2 text-base font-normal tracking-tight text-center text-black px-auto"
			>
				Sell Your Guides
			</a>
			{#if signedIn}
				<a
					href="/trips"
					class="linkUnderline flex items-center justify-center w-36 pt-2 text-base font-normal tracking-tight text-center text-black px-auto"
				>
					Your Trips
				</a>
			{/if}

			<a
				href="/explore"
				class="linkUnderline flex items-center justify-center w-36 pt-2 text-base font-normal tracking-tight text-center text-black px-auto"
			>
				Explore Guides
			</a>
		</div>

		<div class="flex items-center justify-end space-x-2 flex-1 w-full">
			<div
				class={`flex items-center ${
					layoutType === 'itinerary' || layoutType === 'profile'
						? 'p-3 lg:p-0 bg-white dark:bg-brand-gray-5 lg:bg-transparent rounded-full lg:rounded-none shadow-lg lg:shadow-none'
						: 'mx-2'
				} lg:hidden `}
			>
				<button
					aria-label="Open Menu"
					type="button"
					class={`inline-flex items-center text-base font-medium whitespace-nowrap focus:outline-none focus:ring-transparent focus:ring-offset-0 ${
						layoutType === 'itinerary' || layoutType === 'profile'
							? 'dark:text-brand-gray-0 hover:text-white'
							: 'text-black hover:text-brand-gray-5'
					}`}
					on:click={handleClickOnMobile}
					on:touchstart|stopPropagation={handleClickOnMobile}
				>
					<span class="sr-only">Open sidebar</span>
					<span class="hidden mr-3 sm:flex">Menu</span>
					<MenuIcon class={`h-6 w-6 `} />
				</button>
			</div>
			<div class="items-center hidden space-x-4 lg:flex">
				<Button theme="dark" size="sm" on:click={handleStartTrip}>Start Your Trip</Button>
				{#if signedIn}
					<AccountMenu
						class="relative flex-shrink-0"
						user={$state.currentUser}
						on:signout={handleSignOut}
						embedded={false}
					/>
				{:else}
					<button
						id="sign-in"
						aria-label="Sign in"
						on:click={handleShowLoginClick}
						class="text-md font-medium text-black whitespace-nowrap hover:text-gray-800 focus:outline-none"
					>
						Sign in
					</button>
				{/if}
			</div>
		</div>
	</div>
</nav>
